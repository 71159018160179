<template>
  <div>
    <div class="navigation-dsk">
      <b-navbar class="dashboard-navbar fixed-top" toggleable="lg">
        <b-navbar-brand>
          <router-link :to="{ name: 'adminRoute-dashboard' }">
            <img
              src="/img/digital-staging-logo.svg"
              alt="digital-staging-logo"
              style="height: 40px"
            />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="notifications" v-b-toggle.notification-sidebar>
            <font-awesome-icon
              class="icon"
              :icon="{ prefix: 'fas', iconName: 'bell' }"
            />
            <span class="badge">{{ notifications.unread }}</span>
          </b-nav-item>
          <b-nav-item-dropdown class="text-light pr-2" right>
            <template slot="button-content">
              <p class="m-0 mt-1 blue-text font-weight-bold">
                <font-awesome-icon
                  class="mr-2"
                  :icon="{ prefix: 'fas', iconName: 'user-circle' }"
                />
                <span class="mr-3">
                  {{
                    currentUser.user_profile
                      ? currentUser.user_profile.first_name &&
                        currentUser.user_profile.last_name
                        ? currentUser.user_profile.last_name +
                          ' ' +
                          currentUser.user_profile.first_name +
                          '様'
                        : currentUser.email
                      : currentUser.email
                    }}
                </span>
              </p>
            </template>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
      <div class="side-navigation">
        <div class="side-nav-menu">
          <router-link
            :class="
              currentRoute.includes('adminRoute-dashboard')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-dashboard' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-home.svg"
                class="ico-side-nav-menu"
                alt="Home"
              />
              <span>ダッシュボード</span>
            </p>
          </router-link>
          <a
            :class="
              currentRoute.includes('adminRoute-profile-user-info') ||
              currentRoute.includes('adminRoute-profile-email-update') ||
              currentRoute.includes('adminRoute-profile-password-reset')
                ? 'side-nav-item side-nav-item-active '
                : 'side-nav-item'
            "
            href="#"
            not-collapsed
            v-b-toggle.profile-dsk-collapse
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-profile-default.svg"
                class="ico-side-nav-menu"
                alt="Profile"
              />
              <span>プロフィール</span>
              <font-awesome-icon
                class="float-right chevron"
                :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
              />
            </p>
          </a>
          <b-collapse
            id="profile-dsk-collapse"
            class="side-nav-collapse"
            :visible="
              currentRoute.includes('adminRoute-profile-user-info') ||
              currentRoute.includes('adminRoute-profile-email-update') ||
              currentRoute.includes('adminRoute-profile-password-reset')
                ? true
                : false
            "
          >
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-user-info')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-user-info' }"
              v-waves.light
            >
              <p class="m-0 pl-3">プロフィール編集</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-email-update')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-email-update' }"
              v-waves.light
            >
              <p class="m-0 pl-3">メールアドレスの変更</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-password-reset')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-password-reset' }"
              v-waves.light
            >
              <p class="m-0 pl-3">パスワードの変更</p>
            </router-link>
          </b-collapse>
          <router-link
            :class="
              currentRoute.includes('adminRoute-users')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-users' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-user-admin.svg"
                class="ico-side-nav-menu"
                alt="Users"
              />
              <span>ユーザー一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-inquiry')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-inquiry' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-staging-default.svg"
                class="ico-side-nav-menu"
                alt="Staging"
              />
              <span>問い合わせ一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-staging')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-staging' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-staging-default.svg"
                class="ico-side-nav-menu"
                alt="Staging"
              />
              <span>ステージング一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-change-time')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-change-time' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/clock-regular.svg"
                class="ico-side-nav-menu"
                alt="Change-Time"
              />
              <span>タイムスタンプ一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-maintenance')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-maintenance' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-maintenance.svg"
                class="ico-side-nav-menu"
                alt="Maintenance"
              />
              <span>メインテナンス一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-invoice-payment')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-invoice-payment' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-maintenance.svg"
                class="ico-side-nav-menu"
                alt="Maintenance"
              />
              <span>請求書一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-coupon')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-coupon' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-maintenance.svg"
                class="ico-side-nav-menu"
                alt="Maintenance"
              />
              <span>クーポン一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-approval')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-approval' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-approve.svg"
                class="ico-side-nav-menu"
                alt="Approve"
              />
              <span>承認/不承認</span>
            </p>
          </router-link>
          <a
            class="side-nav-item"
            href="#"
            v-waves.light
            v-on:click="$bvModal.show('logout-modal')"
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-logout-default.svg"
                class="ico-side-nav-menu"
                alt="Logout"
              />
              <span>サインアウト</span>
            </p>
          </a>
        </div>
      </div>
    </div>

    <div class="navigation-mbl">
      <b-navbar class="dashboard-navbar fixed-top" toggleable="lg">
        <b-navbar-brand>
          <font-awesome-icon
            class="mr-3 blue-text"
            :icon="{ prefix: 'fas', iconName: 'bars' }"
            v-b-toggle.dashboard-sidebar-ml
          />
          <router-link :to="{ name: 'adminRoute-dashboard' }">
            <img
              src="/img/digital-staging-logo.svg"
              alt="digital-staging-logo"
              style="height: 40px"
            />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <div class="pt-2 d-flex">
            <b-nav-item class="notifications" v-b-toggle.notification-sidebar>
              <font-awesome-icon
                class="icon"
                :icon="{ prefix: 'fas', iconName: 'bell' }"
              />
              <span class="badge">{{ notifications.unread }}</span>
            </b-nav-item>
            <b-nav-item-dropdown toggle-class="text-light pr-2" right>
              <template slot="button-content">
                <p class="m-0 mt-1 blue-text font-weight-bold">
                  <font-awesome-icon
                    :icon="{ prefix: 'fas', iconName: 'user-circle' }"
                    class="mr-2"
                  />
                  <font-awesome-icon
                    :icon="{ prefix: 'fas', iconName: 'caret-down' }"
                  />
                </p>
              </template>
              <a class="dropdown-item" href="#">
                <p class="m-0 font-weight-bold">
                  <font-awesome-icon
                    class="mr-2"
                    :icon="{ prefix: 'fas', iconName: 'user-circle' }"
                  />
                  <span class="mr-3">
                    {{
                      currentUser.user_profile
                        ? currentUser.user_profile.first_name &&
                          currentUser.user_profile.last_name
                          ? currentUser.user_profile.last_name +
                            ' ' +
                            currentUser.user_profile.first_name +
                            '様'
                          : currentUser.email
                        : currentUser.email
                    }}
                  </span>
                </p>
              </a>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      </b-navbar>
      <b-sidebar id="dashboard-sidebar-ml" backdrop>
        <div class="side-nav-menu">
          <router-link
            :class="
              currentRoute.includes('adminRoute-dashboard')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-dashboard' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-home.svg"
                class="ico-side-nav-menu"
                alt="Home"
              />
              <span>ダッシュボード</span>
            </p>
          </router-link>
          <a
            :class="
              currentRoute.includes('adminRoute-profile-user-info') ||
              currentRoute.includes('adminRoute-profile-email-update') ||
              currentRoute.includes('adminRoute-profile-password-reset')
                ? 'side-nav-item side-nav-item-active '
                : 'side-nav-item'
            "
            href="#"
            not-collapsed
            v-b-toggle.profile-mbl-collapse
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-profile-default.svg"
                class="ico-side-nav-menu"
                alt="Profile"
              />

              <span>プロフィール</span>
              <font-awesome-icon
                class="float-right chevron"
                :icon="{ prefix: 'fas', iconName: 'chevron-down' }"
              />
            </p>
          </a>
          <b-collapse
            id="profile-mbl-collapse"
            class="side-nav-collapse"
            :visible="
              currentRoute.includes('adminRoute-profile-user-info') ||
              currentRoute.includes('adminRoute-profile-email-update') ||
              currentRoute.includes('adminRoute-profile-password-reset')
                ? true
                : false
            "
          >
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-user-info')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-user-info' }"
              v-waves.light
            >
              <p class="m-0 pl-3">プロフィール編集</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-email-update')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-email-update' }"
              v-waves.light
            >
              <p class="m-0 pl-3">メールアドレスの変更</p>
            </router-link>
            <router-link
              :class="
                currentRoute.includes('adminRoute-profile-password-reset')
                  ? 'side-nav-collapse-item side-nav-collapse-item-active'
                  : 'side-nav-collapse-item'
              "
              :to="{ name: 'adminRoute-profile-password-reset' }"
              v-waves.light
            >
              <p class="m-0 pl-3">パスワードの変更</p>
            </router-link>
          </b-collapse>
          <router-link
            :class="
              currentRoute.includes('adminRoute-users')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-users' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-user-admin.svg"
                class="ico-side-nav-menu"
                alt="Users"
              />
              <span>ユーザー一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-staging')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-staging' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-staging-default.svg"
                class="ico-side-nav-menu"
                alt="Staging"
              />
              <span>ステージング一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-change-time')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-change-time' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/clock-regular.svg"
                class="ico-side-nav-menu"
                alt="Change-Time"
              />
              <span>タイムスタンプ一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-maintenance')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-maintenance' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-maintenance.svg"
                class="ico-side-nav-menu"
                alt="Maintenance"
              />
              <span>メインテナンス一覧</span>
            </p>
          </router-link>
          <router-link
            :class="
              currentRoute.includes('adminRoute-approval')
                ? 'side-nav-item side-nav-item-active'
                : 'side-nav-item'
            "
            :to="{ name: 'adminRoute-approval' }"
            v-waves.light
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-approve.svg"
                class="ico-side-nav-menu"
                alt="Approve"
              />
              <span>承認/不承認</span>
            </p>
          </router-link>
          <a
            class="side-nav-item"
            href="#"
            v-waves.light
            v-on:click="$bvModal.show('logout-modal')"
          >
            <p class="m-0">
              <img
                src="../../../public/img/icons/ico-logout-default.svg"
                class="ico-side-nav-menu"
                alt="Logout"
              />
              <span>サインアウト</span>
            </p>
          </a>
        </div>
      </b-sidebar>
    </div>

    <b-sidebar
      id="notification-sidebar"
      width="400px"
      right
      no-header
      shadow
      backdrop
      v-model="notifications.visible"
    >
      <template #default="{ hide }">
        <div class="header">
          <h5>メッセージ ({{ notifications.unread }})</h5>
          <h5 class="cursor-pointer" @click="hide">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
          </h5>
        </div>
        <div class="body">
          <div v-if="notifications.data.length > 0">
            <div
              :class="el.unread === 1 ? 'item' : 'item item-read'"
              v-for="(el, i) in notifications.data"
              :key="i"
            >
              <p class="m-0">
                {{ el.title }}
              </p>
              <p class="mt-1 mb-0" style="font-size: 14px">
                {{ formatDate(el.updated_at) }}
              </p>
            </div>
            <p
              class="text-center white-text"
              v-if="notifications.cur_page < notifications.page_count"
              @click="showMoreNotifs"
              style="width: 100%"
            >
              read more>>
            </p>
          </div>
          <div v-else>
            <h5 class="mt-4 text-center white-text">メッセージがありません</h5>
          </div>
        </div>
      </template>
    </b-sidebar>
    <logout-modal></logout-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Moment JS
import moment from 'moment';

// Import Custom Components
import logoutModal from '../Logout-Modal.vue';

// Import Services
import {
  adm_notificationService_GetNotifications,
  adm_notificationService_UpdateNotificationsToRead,
} from '../../services/admin/notifications';

export default {
  components: {
    'logout-modal': logoutModal,
  },

  data() {
    return {
      notifications: {
        data: [],
        unread: 0,
        visible: false,
        page_count: 1,
        cur_page: 1,
      },
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'currentRoute']),
  },

  watch: {
    $route() {
      this.setNotifications();
    },

    'notifications.visible'(newValue) {
      let ids = this.notifications.data
        .map((el) => {
          if (el.unread === 1) {
            return el.id;
          }
        })
        .filter((e) => e != null);

      this.updateNotificationCount(newValue, ids);
    },
  },

  mounted() {
    this.setNotifications();
    console.log('env', process.env.NODE_ENV);
  },
  methods: {
    setNotifications(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      adm_notificationService_GetNotifications({
        token: accessToken,
        page: page ? page : 1,
      })
        .then((response) => {
          let result_data = response.data.result;
          _this.notifications.data = _this.notifications.data.concat(
            result_data.data.data
          );

          _this.notifications.unread = result_data.unread_count;
          _this.notifications.page_count = result_data.data.last_page;
          _this.notifications.cur_page = result_data.data.current_page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showMoreNotifs() {
      this.setNotifications(this.notifications.cur_page + 1);
    },

    async updateNotificationCount(sideBarState, ids) {
      let _this = this;

      _this.notifications.data = _this.notifications.data.sort(function (a, b) {
        return b.unread - a.unread;
      });

      let unread_notifs = _this.notifications.data.filter((n) => n.unread == 1);
      var unread_ids = unread_notifs.map(function (val) {
        return val.id;
      });

      if (!sideBarState) {
        if (unread_ids.length > 0) {
          let accessToken = localStorage.getItem('_accessToken');

          await adm_notificationService_UpdateNotificationsToRead({
            ids: unread_ids,
            token: accessToken,
          })
            .then((response) => {
              let result_data = response.data.result;
              _this.notifications.unread = result_data.unread_count;

              //update read
              unread_ids.forEach((id) => {
                let objIndex = _this.notifications.data.findIndex(
                  (obj) => obj.id == id
                );
                _this.notifications.data[objIndex].unread = 0;
              });
            })
            .catch((error) => {
              console.log(error);
            });
          _this.notifications.data = [];
          this.setNotifications();
        }
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:MM');
    },
  },

  async googleAccountIsSignedIn() {
    let _this = this;

    try {
      let googleUser = await _this.$gAuth.isAuthorized;
      console.log(googleUser);
    } catch (error) {
      console.error(error);

      return null;
    }
  },
};
</script>

<style scoped>
/* CSS Init */
.side-nav-collapse .side-nav-collapse-item {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px 15px 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.side-nav-collapse .side-nav-collapse-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.side-nav-collapse .side-nav-collapse-item-active {
  background: #fff;
  color: #034ea1;
  font-weight: bold;
}

#notification-sidebar .header {
  display: flex;
  justify-content: space-between;
  background: #034ea1;
  color: #fff;
  padding: 28px 10px 10px 10px;
  border-bottom: 1px solid #fff;
}

#notification-sidebar .body {
  background: #034ea1;
  height: 100%;
  padding: 10px 10px 40px 10px;
  overflow: auto;
}

#notification-sidebar .body .item {
  color: #fff;
  border: 1px solid #fff;
  padding: 8px;
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
}

#notification-sidebar .body .item-read {
  opacity: 0.5;
}

/* Desktop Main Navbar and Sidebar */
.navigation-dsk {
  display: block;
}

@media only screen and (max-width: 991px) {
  .navigation-dsk {
    display: none;
  }
}

.navigation-dsk .dashboard-navbar {
  background: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-dsk .dashboard-navbar .notifications {
  position: relative;
  margin: -4px 18px 0 0;
}

.navigation-dsk .dashboard-navbar .notifications .icon {
  color: #167ae8;
  font-size: 17px;
}

.navigation-dsk .dashboard-navbar .notifications .badge {
  position: absolute;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  top: 4px;
  right: -2px;
}

.navigation-dsk .side-navigation {
  background: #fff;
  position: fixed;
  top: 50px;
  height: 100%;
  width: 300px;
  overflow: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.navigation-dsk .side-nav-menu {
  margin-top: 50px;
  padding: 10px 20px 20px 20px;
  margin-bottom: 50px;
}

.navigation-dsk .side-nav-menu .side-nav-item {
  margin-bottom: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  /* padding: 15px 10px 15px 10px; */
  padding: 0px 10px 0px 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-dsk .side-nav-menu .side-nav-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navigation-dsk .side-nav-menu .side-nav-item-active {
  background: #034ea1;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* Mobile Main Navbar and Sidebar */
.navigation-mbl {
  display: none;
}

@media only screen and (max-width: 991px) {
  .navigation-mbl {
    display: block;
  }
}

.navigation-mbl .dashboard-navbar {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-mbl .dashboard-navbar .notifications {
  position: relative;
  margin: -4px 26px 0 0;
}

.navigation-mbl .dashboard-navbar .notifications .icon {
  color: #167ae8;
  font-size: 17px;
}

.navigation-mbl .dashboard-navbar .notifications .badge {
  position: absolute;
  background: #ff0000;
  color: #fff;
  border-radius: 20px;
  top: 4px;
  right: -10px;
}

.navigation-mbl .navbar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navigation-mbl .side-nav-menu {
  padding: 20px;
}

.navigation-mbl .side-nav-menu .side-nav-item {
  margin-bottom: 10px;
  background: #e9e9e9;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.navigation-mbl .side-nav-menu .side-nav-item:hover {
  background: #757575;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navigation-mbl .side-nav-menu .side-nav-item-active {
  background: #034ea1;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ico-side-nav-menu {
  height: 20px;
  width: 20px;
  margin: 13px 37px 13px 10px;
}

.ico-logout-default {
  width: 18px;
  height: 19px;
  margin: 13px 37px 13px 10px;
}

.chevron {
  margin-top: 18px;
}

a.side-nav-item {
  display: none;
}

.side-nav-item-active .ico-side-nav-menu {
  filter: invert(1);
}
</style>
